import Loadable from 'react-loadable'

import Loading from './loading'
import LoadError from './load-error'

export default (componentLoader) =>
  Loadable({
    loader: () => componentLoader().catch(() => LoadError),
    loading: Loading,
  })
