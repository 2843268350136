import React from 'react'
import { Empty, Button } from 'antd'

export default () => {
  return (
    <div style={{ paddingTop: 200, textAlign: 'center' }}>
      <Empty description={false} />
      <Button
        type={'primary'}
        className={'mt20'}
        onClick={() => window.location.reload()}
      >
        刷新试试
      </Button>
    </div>
  )
}
