import React from 'react'
import { renderRoutes } from 'src/router/utils/renderRoutes'
import routers from './router'
import { HashRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'

moment.locale('zh-cn')

const App = () => {
  return (
    <ConfigProvider locale={zhCN}>
      <HashRouter>{renderRoutes(routers)}</HashRouter>
    </ConfigProvider>
  )
}
export default App
