import React from 'react'
import { useLocation } from 'react-router-dom'
import RouterContainer from 'src/router/components/router-container'
import styled from '@emotion/styled'
import head from './assets/head.png'
import logo from 'src/assets/logo.png'
import title01 from './assets/title.png'
import title02 from './assets/title2.png'
import Bottom from './components/Bottom'
import LogoLinks from './components/Links'
import { map, addIndex } from 'ramda'
import { Divider } from 'antd'

const HeaderWrapper = styled.div`
  background-image: url(${head});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #ffffff;
  height: 220px;
  padding-top: 64px;
`

const Header = styled.div`
  max-width: 1390px;
  margin: 0 auto;
`

const Content = styled.div`
  min-height: calc(100% - 528px);
  padding: 0 15px;
`

const Footer = styled.div``

const HeadLine = styled.div`
  height: 10px;
  background: #024486;
  margin-top: 5px;
`

const Logo = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 24px;
`

const Title = styled.img`
  width: 780px;
  height: 90px;
  vertical-align: top;
`

const LinksWrapper = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #e3e3e3;
`

const LinksTitle = styled.div`
  font-size: 18px;
  color: #040000;
  max-width: 1039px;
  margin: 0 auto 19px;
`

const LinksWrap = styled.div`
  max-width: 1039px;
  margin: 0 auto;
  // height: 90px;
`

const links = [
  {
    name: '国家体育总局社会体育指导中心',
    link: '',
  },
  {
    name: '中国奥利匹克委员会',
    link: '',
  },
  {
    name: '北京健美体操舞蹈网',
    link: '',
  },
  {
    name: '中国老年人体育协会',
    link: '',
  },
  {
    name: '中国龙狮运动协会',
    link: '',
  },
  {
    name: '中国飞镖协会',
    link: '',
  },
  {
    name: '中国钓鱼运动协会',
    link: '',
  },
]

const Links = styled.div`
  text-align: center;
`

const Link = styled.span`
  cursor: pointer;
  color: #acacac;
  font-size: 12px;

  :hover {
    color: rgba(172, 172, 172, 0.7);
  }
`

const GlobalLayout = (props) => {
  const { route } = props  
  const location = useLocation();
  console.log('location@', location);
  const banners = {  
    '/search/1': title02,
    '/performanceList': title02
  };
  
   let titleImage = banners[location.pathname] || title01;
   // 检查当前路径是否匹配详情页的模式
   const detailPattern = /^\/performanceDetail\/\d+\/\d+$/;
   // 如果路径不在banners对象中，尝试模糊匹配
    if (detailPattern.test(location.pathname)) {
      titleImage = title02; // 如果匹配详情页模式，使用title02
    } 
    

  return (
    <>
      <HeaderWrapper>
        <Header>
          <Logo src={logo} />
          <Title src={titleImage} />
        </Header>
      </HeaderWrapper>

      <HeadLine />
      <Content>
        <RouterContainer route={route} />
      </Content>
      <Footer>
        <LinksWrapper>
          <LinksTitle>友情链接</LinksTitle>
          <LinksWrap>
            <LogoLinks />
            <Links>
              {addIndex(map)(
                (e, i) => (
                  <span key={i}>
                    <Link onClick={() => window.open(e.link)}>{e.name}</Link>
                    {i < links.length - 1 && <Divider type="vertical" />}
                  </span>
                ),
                links
              )}
            </Links>
          </LinksWrap>
        </LinksWrapper>
        <Bottom />
      </Footer>
    </>
  )
}

export default GlobalLayout
