import Layout from './components/layouts'
import AsyncComponent from './components/async-component'

export default [
  {
    path: '/',
    component: Layout,
    routes: [
      {
        name: '中舞音艺术测评认证委员会',
        path: '/',
        exact: true,
        component: AsyncComponent(() => import('src/pages/home')),
      },
      {
        name: '查询',
        path: '/search/:type',
        exact: true,
        component: AsyncComponent(() => import('src/pages/search')),
      },
      {
        name: '证书列表',
        path: '/list',
        exact: true,
        component: AsyncComponent(() => import('src/pages/list')),
      },
      {
        name: '证书详情',
        path: '/detail/:id',
        exact: true,
        component: AsyncComponent(() => import('src/pages/detail')),
      },
      {
        name: '等级证书详情',
        path: '/gradDetail/:student_id/:id',
        exact: true,
        component: AsyncComponent(() => import('src/pages/gradDetail')),
      },
      {
        name: '赛事作品列表',
        path: '/performanceList',
        exact: true,
        component: AsyncComponent(() => import('src/pages/performanceList')),
      },
      {
        name: '赛事作品详情',
        path: '/performanceDetail/:type/:id', 
        exact: true,
        component: AsyncComponent(() => import('src/pages/performanceDetail')),
      },
    ],
  },
]
