import React from 'react'
import { Spin } from 'antd'

export default () => {
  return (
    <div style={{ paddingTop: 200, textAlign: 'center' }}>
      <Spin size="large" />
    </div>
  )
}
