import React, { useState } from 'react'
import styled from '@emotion/styled'
import { map } from 'ramda'
import p1 from '../assets/links/p1.png'
import p2 from '../assets/links/p2.png'
import p3 from '../assets/links/p3.png'
import p4 from '../assets/links/p4.png'
import p5 from '../assets/links/p5.png'
import p6 from '../assets/links/p6.png'
import p7 from '../assets/links/p7.png'
import p8 from '../assets/links/p8.png'
import leftArrow from '../assets/left-arrow.png'
import rightArrow from '../assets/right-arrow.png'
import { css } from '@emotion/core'

const links = [
  {
    img: p1,
    link: '',
    id: 1,
  },
  {
    img: p2,
    link: '',
    id: 2,
  },
  {
    img: p3,
    link: '',
    id: 3,
  },
  {
    img: p4,
    link: '',
    id: 4,
  },
  {
    img: p5,
    link: '',
    id: 5,
  },
  {
    img: p6,
    link: '',
    id: 6,
  },
  {
    img: p7,
    link: '',
    id: 7,
  },
  {
    img: p8,
    link: '',
    id: 8,
  },
  {
    img: p3,
    link: '',
    id: 9,
  },
  {
    img: p4,
    link: '',
    id: 10,
  },
]

const logoWidth = 100
const logoMarginRight = 16

const Wrapper = styled.div`
  padding: 0 25px;
  text-align: center;
`

const Logo = styled.img`
  width: ${logoWidth}px;
  height: 44px;
  margin-right: ${logoMarginRight}px;

  :last-child {
    margin-right: 0;
  }
`

const ArrowWrapper = styled.div`
  width: 25px;
  height: 25px;
  background: #acacac;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
`

const ArrowImg = styled.img`
  width: 20px;
  height: 20px;
`

const Arrow = ({ src, float, onClick = () => {} }) => {
  return (
    <ArrowWrapper
      onClick={onClick}
      css={css`
        float: ${float};
      `}
    >
      <ArrowImg src={src} />
    </ArrowWrapper>
  )
}

const TransWrapperWidth = logoWidth * 8 + logoMarginRight * 7

const TransWrapper = styled.div`
  overflow: hidden;
  width: ${TransWrapperWidth}px;
  height: 44px;
  margin: 0 auto;
  display: inline-block;
`

const LinksWrapperWidth =
  links.length * logoWidth + (links.length - 1) * logoMarginRight
const actionWidth = -(LinksWrapperWidth - TransWrapperWidth)
const baseWidth = logoWidth + logoMarginRight

const LinksWrapper = styled.div`
  width: ${LinksWrapperWidth}px;
  transition: all 0.3s;
`

export default () => {
  const [poi, setPoi] = useState(0)

  const prev = () => {
    if (poi >= 0) return
    setPoi(poi + baseWidth)
  }

  const next = () => {
    if (poi <= actionWidth) return
    setPoi(poi - baseWidth)
  }

  return (
    <Wrapper>
      {poi < 0 && <Arrow src={leftArrow} float={'left'} onClick={prev} />}

      <TransWrapper>
        <LinksWrapper
          css={css`
            transform: translateX(${poi}px);
          `}
        >
          {map(
            (e) => (
              <Logo
                key={e.id}
                src={e.img}
                onClick={() => window.open(e.link)}
              />
            ),
            links
          )}
        </LinksWrapper>
      </TransWrapper>

      {poi > actionWidth && (
        <Arrow src={rightArrow} float={'right'} onClick={next} />
      )}
    </Wrapper>
  )
}
