import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { map } from 'ramda'
const location = window.location

function renderInterceptor(route, props = {}, extraProps = {}) {
  const { redirect, name } = route

  // 跳转附加属性
  if (Boolean(redirect)) {
    if (redirect.startsWith('http')) {
      location.href = redirect

      return null
    } else {
      return <Redirect to={redirect} />
    }
  }

  document.title = name
  return route.render ? (
    route.render({ ...props, ...extraProps, route: route })
  ) : (
    <route.component {...props} {...extraProps} route={route} />
  )
}

export const renderRoutes = (routers, extraProps, switchProps) => {
  return (
    <Switch {...switchProps}>
      {map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={(props) => renderInterceptor(route, props, extraProps)}
          />
        )
      }, routers)}
    </Switch>
  )
}
