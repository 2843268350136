import React from 'react'
import styled from '@emotion/styled'
// import logo from '../../../../assets/logo.png'
import qrcode from '../assets/qrcode.jpg'
import mp from '../assets/mp.jpg'

const BottomWrapper = styled.div`
  width: 795px;
  margin: 0 auto;
  overflow: hidden;
  padding: 7px 0;
`

const BottomLogo = styled.img`
  width: 85px;
  height: 85px;
  margin-right: 32px;
  float: left;
  margin-top: 7px;
`

const BottomDetails = styled.div`
  float: left;
  font-size: 12px;
  margin-top: 7px;
  color: #1a1a1a;
`

const BottomQrCode = styled.img`
  width: 87px;
  height: 87px;
  display: block;
  margin: 0 auto;
`

const QrCodeWrapper = styled.div`
  float: left;
  padding-left: 39px;
  border-left: 1px solid #e3e3e3;
  margin-left: 85px;
`

export default () => {
  return (
    <BottomWrapper>
      <BottomLogo src={mp} />
      <BottomDetails>
        <div>Copyright © 2020-2021 cdsf.org.cn All Rights Reserved </div>
        <div>中舞音艺术文化测评认证委员会 版权所有</div>
        <div>京ICP备2020044892号</div>
        <div>商务合作：15161201000 0527-81981855</div>
      </BottomDetails>
      <QrCodeWrapper>
        <BottomQrCode src={qrcode} />
        <div>扫一扫，时时刻刻舞动起来</div>
      </QrCodeWrapper>
    </BottomWrapper>
  )
}
